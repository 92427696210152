import * as Mutations from "@/store/mutations.type";

export default {
  [Mutations.SET_SELECTED_DEALER](state, dealer) {
    state.dealer = dealer;
  },
  [Mutations.SET_SERVICE_LOADING](state, loading) {
    state.loading = loading;
  },
  [Mutations.SET_SERVICEREQUEST_ERROR](state, error) {
    state.error = error;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_PICKUP](state, data) {
    state.serviceRequestsPickUp = data;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_DROPOFF](state, data) {
    state.serviceRequestsDropOff = data;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_COUNT](state, count) {
    state.count = count;
  },
  [Mutations.SET_ALL_SERVICEREQUESTS_COUNT_DROPOFF](state, count) {
    state.dropOffCount = count;
  },
  [Mutations.SET_PICKUP_SELECTED](state, payload) {
    const { action, data } = payload;
    let newPickUpSelected = state.pickUpSelected;

    if (action === "add") {
      data.map((item) => {
        if (!newPickUpSelected.find((e) => e.id === item.id)) {
          newPickUpSelected.push(item);
        }
      });
    } else if (action === "remove") {
      data.map((item) => {
        newPickUpSelected = newPickUpSelected.filter((e) => e.id !== item.id);
      });
    } else if (action === "clear") {
      newPickUpSelected = [];
    }
    state.pickUpSelected = newPickUpSelected;
  },
  [Mutations.SET_DROPOFF_SELECTED](state, payload) {
    const { action, data } = payload;
    let newDropOffSelected = state.dropOffSelected;
    if (action === "add") {
      data.map((item) => {
        if (!newDropOffSelected.find((e) => e.id === item.id)) {
          newDropOffSelected.push(item);
        }
      });
    } else if (action === "remove") {
      data.map((item) => {
        newDropOffSelected = newDropOffSelected.filter((e) => e.id !== item.id);
      });
    } else if (action === "clear") {
      newDropOffSelected = [];
    }
    state.dropOffSelected = newDropOffSelected;
  }
};
