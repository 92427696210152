export default {
  dealer: null,
  loading: false,
  error: "",
  serviceRequestsPickUp: [],
  serviceRequestsDropOff: [],
  count: 0,
  dropOffCount: 0,
  pickUpSelected: [],
  dropOffSelected: []
};
