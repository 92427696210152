// auth
export const CHECK_AUTH = "checkAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const SEND_EMAIL_VERIFICATION = "sendEmailVerification";
export const GET_CURRENT_USER = "getCurrentUser";
export const CHANGE_PASSWORD = "changePassword";
export const GET_ALL_USERS = "getAllUsers";
export const RESET_PASSWORD_CODE = "resetPasswordCode";
export const REQUEST_RESET_PASSWORD = "requestResetPassword";
export const REAUTHENTICATE = "reauthenticate";

// Dealers
export const GET_ALL_DEALERS = "getAllDealers";
export const CREATE_DEALER = "createDealer";
export const DEALER_SAVE_SELECTED = "dealerSaveSelected";
export const DEALER_ADD_FILTER = "dealerAddFilter";
export const DEALER_REMOVE_FILTER = "dealerRemoveFilter";
export const ORDER_DEALERS = "orderDealers";
export const SEARCH_DEALERS = "searchDealers";
export const DEALER_REMOVE_ALL_FILTER = "dealerRemoveAllFilter";
export const UPDATE_DEALER = "updateDealer";
export const DEALER_AMAZON_EBAY_CHART_FILTER = "dealerAmazonEbayChartFilter";
export const DEALER_AMAZON_EBAY_CHART_REMOVE_FILTER =
  "dealerAmazonEbayChartRemoveFilter";

// Reports
export const CREATE_REPORT = "createReport";
export const GET_ALL_REPORTS = "getAllReports";

// Sales
export const GET_SALES = "getSales";
export const RESET_SALES = "resetSales";
export const FILTER_SALES = "filterSales";

// Orders
export const CREATE_ORDER = "createOrder";
export const GET_ALL_ORDERS = "getAllOrders";
export const UPDATE_ORDER = "updateOrder";
export const ORDER_ADD_FILTER = "orderAddFilter";
export const ORDER_REMOVE_ALL_FILTER = "orderRemoveAllFilter";
export const SEARCH_ORDERS = "searchOrders";
export const ORDER_REMOVE_FILTER = "orderRemoveFilter";
export const ORDER_SAVE_SELECTED = "orderSaveSelected";
export const GET_ORDERS_FROM_MONGO = "getOrdersFromMongo";
export const GET_SINGLE_ORDER_FROM_MONGO = "getSingleOrderFromMongo";
export const SEND_ORDER_NOTIFY = "sendOrderNotify";
export const SEND_ORDER_INVOICE = "sendOrderInvoice";
export const SEND_ORDER_MARKETPLACE_INVOICE = "sendOrderMarketplaceInvoice";
export const UPDATE_ORDER_FROM_MONGO = "updateOrderFromMongo";
export const CHECK_SENT = "checkSent";

// Charts
export const AMAZON_EBAY_CHART_ADD_FILTER = "amazonEbayChartAddFilter";
export const AMAZON_EBAY_CHART_REMOVE_FILTER = "amazonEbayChartRemoveFilter";

//Logs
export const CREATE_LOG = "createLog";

// Commercial
export const CREATE_CUSTOMER = "createCustomer";
export const GET_ALL_CUSTOMERS = "getAllCustomers";
export const SEARCH_CUSTOMERS = "searchCustomers";
export const CUSTOMER_SAVE_SELECTED = "customerSaveSelected";
export const UPDATE_CUSTOMER = "updateCustomer";
export const DELETE_CUSTOMER = "deleteCustomer";
export const SORT_ORDER_CUSTOMER = "sortOrderCustomer";
export const SEND_CUSTOMER_REPORT_CONDITION = "sendCustomerReportCondition";
export const ADD_CONDITION_REPORT_TIMESTAMP = "addConditionReportTimestamp";

// Logs
export const LOG_EVENT = "logEvent";
export const GET_LOGS = "getLogs";

// Ably
export const ENTER_ROOM = "enterRoom";
export const LEAVE_ROOM = "leaveRoom";

// Payments
export const GET_PAYMENTS = "getPayments";

// POS
export const GET_PRODUCTS = "getProducts";
export const RESET_CART = "resetCart";
export const UPDATE_CART_CUSTOMER = "updateCartCustomer";
export const UPDATE_CART_ITEMS = "updateCartItems";
export const POST_ORDER = "postOrder";
export const GET_ALL_COUPONS = "getAllCoupons";
export const UPDATE_COUPON = "updateCoupon";

// Revenue
export const GET_ALL_REVENUE = "getAllRevenue";
export const UPDATE_REVENUE = "updateRevenue";
export const GET_ALL_SPAREPARTS_REVENUE = "getAllSparepartsRevenue";
export const UPDATE_SPAREPARTS_REVENUE = "updateSparepartsRevenue";
export const SEND_DEALER_REVENUE_PDF = "sendDealerRevenuePDF";

// Visitor Log
export const GET_ALL_BRANCHES = "getAllBranches";
export const SET_PERSONNEL = "setPersonnel";
export const CREATE_VISITOR = "createVisitor";
export const GET_ALL_VISITORS = "getAllVisitor";
export const SIGN_OUT_VISITOR = "signOutVisitor";
export const DELETE_VISITOR_LOG = "deleteVisitorLog";
export const ADD_VISITOR_FILTER = "addVisitorFilter";
export const REMOVE_VISITOR_FILTER = "removeVisitorFilter";
export const CLEAR_VISITOR_FILTERS = "clearVisitorFilters";
export const SEARCH_VISITORS = "searchVisitors";
export const SORT_VISITORS = "sortVisitors";
export const SELECT_TO_DELETE_VISITOR_LOG = "selectToDeleteVisitorLog";
export const GET_TODAYS_VISITORS = "getTodaysVisitors";

// Service Request
export const GET_ALL_DEALERS_UNPROTECTED = "getAllDealersUnprotected";
export const UPDATE_SELECTED_DEALER = "updateSelectedDealer";
export const CREATE_SERVICE_REQUEST = "createServiceRequest";
export const CREATE_SERVICE_REQUEST_PICKUP = "createServiceRequestPickUp";
export const GET_ALL_SERVICEREQUESTS_PICKUP = "getAllServiceRequestsPickUp";
export const GET_ALL_SERVICEREQUESTS_DROPOFF = "getAllServiceRequestsDropOff";
export const BOOK_OPEN_FREIGHT = "bookOpenFreight";
export const SERVICE_PICKUP_REBOOK_RESEND = "servicePickUpRebookResend";
export const UPDATE_SERVICE_REQUEST_PICKUP = "updateServiceRequestPickUp";
export const SERVICE_REQUEST_CREATE_RETURN_CONSIGNMENT =
  "serviceRequestCreateReturnConsignment";
export const SERVICE_PICKUP_RESEND_EMAIL = "servicePickUpResendEmail";
export const SERVICE_REQUEST_GET_TRACKING = "serviceRequestGetTracking";
export const GET_ALL_SERVICEREQUESTS_DROPOFF_CSV =
  "getAllServiceRequestsDropOffCSV";
export const GET_ALL_SERVICEREQUESTS_PICKUP_CSV =
  "getAllServiceRequestsPickUpCSV";

// Register
export const INITIAL_VALIDATE = "initialValidate";
export const CREATE_ACCOUNT_REGISTER_REQUEST = "createAccountRegisterRequest";
export const GET_ALL_REGISTRATIONS = "getAllRegistrations";
export const DELETE_REGISTRATION_REQUEST = "deleteRegistrationRequest";
export const UPDATE_REGISTRATION_REQUEST = "updateRegistrationRequest";
export const CREATE_REGISTER_ACCOUNT = "createRegisterAccount";
export const GET_ALL_VALID_REGISTRATION_COUNT = "getAllValidRegistrationCount";
export const DELETE_DEALERUSER_ACCOUNT = "deleteDealerUserAccount";

// Notifications
export const GET_NOTIFICATION = "getNotification";
export const UPDATE_NOTIFICATION_READ = "updateNotificationRead";
export const PUBLISH_ANNOUNCEMENT = "publishAnnouncement";
