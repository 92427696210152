export const ROLES = {
  ADMIN: {
    name: "ADMIN",
    allowed: [
      "dealers",
      "admin",
      "order",
      "online_store",
      "commercial",
      "activity_logs",
      "payments",
      "pos",
      "visitor_logs",
      "drop_off",
      "pick_up",
      "pick_up_training"
    ]
  },
  OFFICE_MANAGER: {
    name: "OFFICE_MANAGER",
    allowed: [
      "dealers",
      "admin",
      "order",
      "online_store",
      "payments",
      "visitor_logs"
    ]
  },
  ACCOUNT: {
    name: "ACCOUNT",
    allowed: ["dealers", "payments", "order"]
  },
  COMMERCIAL: {
    name: "COMMERCIAL",
    allowed: ["commercial"]
  },
  MARKETPLACE: {
    name: "MARKETPLACE",
    allowed: ["dealers", "online_store"]
  },
  SUPERUSER: {
    name: "SUPERUSER",
    allowed: ["dealers", "online_store", "order", "payments", "activity_logs"]
  },
  FINANCE: {
    name: "FINANCE",
    allowed: ["order", "online_store", "payments"]
  },
  RECEPTION: {
    name: "RECEPTION",
    allowed: ["dealers", "online_store", "visitor_logs", "payments"]
  },
  DEALER: {
    name: "DEALER",
    allowed: ["pick_up"]
  },
  SERVICE: {
    name: "SERVICE",
    allowed: ["dealers", "admin", "drop_off", "pick_up"]
  },
  TRAINING: {
    name: "TRAINING",
    allowed: ["pick_up_training"]
  }
};

export const PAGES = [
  {
    icon: "Dealers",
    pageName: "dealers",
    title: "Dealers"
  },
  {
    icon: "MarketPlace",
    pageName: "online_store",
    title: "Market Place"
  },
  {
    icon: "Commercial",
    pageName: "commercial",
    title: "Commercial"
  },
  {
    icon: "Admin",
    pageName: "admin",
    title: "Admin"
  },
  {
    icon: "SalesIcon",
    pageName: "order",
    title: "Orders"
  },
  {
    icon: "Activity",
    pageName: "activity_logs",
    title: "Activity Logs"
  },
  {
    icon: "Payment",
    pageName: "payments",
    title: "Payments"
  },
  {
    icon: "Pos",
    pageName: "pos",
    title: "Point of Sale"
  },
  {
    icon: "Visitor",
    pageName: "visitor_logs",
    title: "Visitor Logs"
  },
  {
    icon: "ServiceIcon",
    pageName: "service_request_dashboard",
    title: "Service Request",
    subMenu: [
      {
        icon: "DropOff",
        pageName: "drop_off",
        title: "Drop Off"
      },
      {
        icon: "PickUp",
        pageName: "pick_up",
        title: "Pick Up"
      }
    ]
  },
  {
    icon: "TrainingIcon",
    pageName: "training",
    title: "Training",
    subMenu: [
      {
        icon: "PickUp",
        pageName: "pick_up_training",
        title: "Service Pick Up"
      }
    ]
  }
];
