import { uploadFileByAPI } from "@/common/salesAppApi";
export const handleFileChangeAPI = async (e) => {
  try {
    const { url } = await uploadFileByAPI(
      `${e.target.files[0].name.replace(/\s+/g, "-").toLowerCase()}`,
      e.target.files[0]
    );
    return url;
  } catch (error) {
    console.log(error);
  }
};

export const parseServiceDropOffToCsvFormatFull = (services) => {
  const headings = [
    "DATE",
    "BRANCH",
    "CUSTOMER TYPE",
    "NAME",
    "EMAIL",
    "PHONE",
    "ADDRESS",
    "STATE",
    "CITY",
    "ADDRESSLINE",
    "POSTCODE",
    "DELIVERY NAME",
    "DELIVERY EMAIL",
    "DELIVERY PHONE",
    "DELIVERY ADDRESS",
    "DELIVERY STATE",
    "DELIVERY CITY",
    "DELIVERY ADDRESSLINE",
    "DELIVERY POSTCODE",
    "MODEL",
    "SERIAL NUMBER",
    "PURCHASE DATE",
    "INCLUSION",
    "PROBLEM",
    "IMAGES"
  ];
  return [
    headings,
    ...services.map((service) => {
      const imgUrls =
        service.imgUrl && Array.isArray(service.imgUrl)
          ? service.imgUrl?.join(", ")
          : service.imgUrl;
      return [
        service.dateDropOff?.date ?? "",
        service.dropOffBranch?.label ?? "",
        service.consumer?.toUpperCase() ?? "",
        service.consumerInfo?.name ?? "",
        service.consumerInfo?.email ?? "",
        service.consumerInfo?.phone ?? "",
        service.consumerInfo?.address?.address ?? "",
        service.consumerInfo?.address?.state ?? "",
        service.consumerInfo?.address?.city ?? "",
        service.consumerInfo?.address?.addressLine ?? "",
        service.consumerInfo?.address?.postalCode ?? "",
        service.deliveryInfo?.name ?? "",
        service.deliveryInfo?.email ?? "",
        service.deliveryInfo?.phone ?? "",
        service.deliveryInfo?.address?.address ?? "",
        service.deliveryInfo?.address?.state ?? "",
        service.deliveryInfo?.address?.city ?? "",
        service.deliveryInfo?.address?.addressLine ?? "",
        service.deliveryInfo?.address?.postCode ?? "",
        service.model ?? "",
        service.serialNumber ?? "",
        service.purchaseDate ?? "",
        service.inclusion?.join(", ") ?? "",
        service.problem ?? "",
        imgUrls ?? ""
      ];
    })
  ];
};

export const parseServicePickUpToCsvFormatFull = (services) => {
  const headings = [
    "DATE",
    "BRANCH",
    "SERVICE CALL ID",
    "PRIORITYID",
    "NAME",
    "EMAIL",
    "PHONE",
    "ADDRESS",
    "STATE",
    "CITY",
    "ADDRESSLINE",
    "POSTCODE",
    "SERVICE AGREEMENT",
    "CONSIGNMENT ID",
    "LABEL PDF",
    "MANIFEST LABEL",
    "PICKUP ID",
    "STATUS",
    "PICKUP INSTRUCTION",
    "DESCRIPTION OF FAULTS",
    "ROBOTS"
  ];
  return [
    headings,
    ...services.map((service) => {
      const robots = service.robots?.length
        ? service.robots
            ?.map(
              (robot) =>
                `${robot.robotName}(SN:${robot.serialNumber}, ${robot.caseId}${
                  robot.returnConsignment
                    ? ` Return Consignment : ${robot.returnConsignment?.shipping?.consignment}`
                    : ""
                })`
            )
            .join(", ")
        : "";
      return [
        service?.date ?? "",
        service?.branch?.value ?? "",
        service?.caseId ?? "",
        service?.priorityId ?? "",
        service?.basicInfo?.name ?? "",
        service?.basicInfo?.email ?? "",
        service?.basicInfo?.phone ?? "",
        service?.basicInfo?.address?.address ?? "",
        service?.basicInfo?.address?.state ?? "",
        service?.basicInfo?.address?.city ?? "",
        service?.basicInfo?.address?.addressLine ?? "",
        service?.basicInfo?.address?.postalCode ?? "",
        service?.agreement ? "YES" : "NO",
        service?.shipping?.consignment ?? "",
        service?.shipping?.label_pdf ?? "",
        service?.manifest?.label ?? "",
        service?.pickup?.pickup ?? "",
        service?.status.toUpperCase() ?? "",
        service?.notes ?? "",
        service?.robotNotes ?? "",
        robots ?? ""
      ];
    })
  ];
};
